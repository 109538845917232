import React from "react";
import Logo from "../Logo";
import styles from "./Header.module.scss";

function Header({ logo, formType, title }: Props) {
    return (
        <div className={styles["header"]}>
            <Logo src={logo} />
            <div className={styles["info"]}>
                <h1>Submit your Ticket for {title}</h1>
                <span className={styles["form-type"]}>{formType}</span>
            </div>
        </div>
    );
}

type Props = {
    logo: string,
    formType: string
    title: string
}

export default Header;
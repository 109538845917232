import {Argument} from "classnames";

declare global {
    type CX = (...args: Argument[]) => string;
}

// eslint-disable-next-line no-shadow
export enum FormType {
    "Post Opening" = "Post Opening",
    "Countdown" = "Countdown",
    "Pre Opening Office" = "Pre Opening Office"
}

export type FormValues = {
    firstName: string;
    lastName: string;
    emailAddress: string;
    areaCode?: string;
    phoneNumber?: string;
    department: string;
    problemArea: string;
    issue: string;
    roomNumbers?: string;
    location?: string;
    attachment1: Array<File>;
    attachment2: Array<File>;
    attachment3: Array<File>;
    attachment4: Array<File>;
};

export type FormParams = {
    relatedSite: string,
    siteTitle: string,
    relatedProject: string,
    formType: FormType,
    logo?: string,
    engineeringTicketAssignee?: string,
}
import React from "react";
import { Controller, ControllerRenderProps, type UseFormReturn } from "react-hook-form";

import classnames from "classnames/bind";

import FieldContainer from "../FieldContainer";
import Select from "../Select";
import Textarea from "../Textarea";
import Input from "../Input";
import styles from "./Form.module.scss";
import Button from "../Button";
import { FormValues } from "src/types";
import DropFileInput from "../DropFileInput/DropFileInput.com";
import { FileWithPath } from "react-dropzone";
import GhostButton from "../GhostButton/GhostButton.com";

const cx: CX = classnames.bind(styles);

const departmentOptions = [
    {
        value: "Administration",
        label: "Administration",
    },
    {
        value: "Countdown Team Support",
        label: "Countdown Team Support",
    },
    {
        value: "Engineering",
        label: "Engineering",
    },
    {
        value: "F&B",
        label: "F&B",
    },
    {
        value: "Finance",
        label: "Finance",
    },
    {
        value: "Front Office",
        label: "Front Office",
    },
    {
        value: "HR",
        label: "HR",
    },
    {
        value: "HSK",
        label: "HSK",
    },
    {
        value: "IT",
        label: "IT",
    },
    {
        value: "Reservations & Revenue",
        label: "Reservations & Revenue",
    },
    {
        value: "Sales & Marketing",
        label: "Sales & Marketing",
    },
    {
        value: "Security & Loss Prevention",
        label: "Security & Loss Prevention",
    },
];

const reportDepartmentOptions = [
    {
        value: "IT",
        label: "IT",
    },
    {
        value: "Engineering",
        label: "Engineering",
    },
];

const reportDepartmentOptionsITOnly = [
    reportDepartmentOptions[0],
];

function Form(props: Props) {
    const { form, engineeringTicketAssignee, onSubmit } = props;
    const { register, setValue, reset, formState: { errors, isSubmitting }, watch, handleSubmit, control } = form;

    return (
        <div className={cx("wrapper")}>
            <form
                className={cx("form")}
                onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
                    void handleSubmit(onSubmit)(event)
                }>
                <div className={cx("body")}>
                    <FieldContainer
                        label="Name"
                        required
                        id="firstName"
                        error={errors?.firstName?.message || errors?.lastName?.message}
                    >
                        <div className={cx("column")}>
                            <Input
                                {...register("firstName")}
                                placeholder="First Name"
                                value={watch("firstName")}
                                error={errors?.firstName?.message}
                            />
                        </div>
                        <div className={cx("column")}>
                            <Input
                                {...register("lastName")}
                                placeholder="Last Name"
                                value={watch("lastName")}
                                error={errors?.lastName?.message}
                            />
                        </div>
                    </FieldContainer>
                    <div className={cx("row")}>
                        <FieldContainer
                            label="Email"
                            required
                            id="emailAddress"
                            layoutType="half"
                            error={errors?.emailAddress?.message}
                        >

                            <Input
                                {...register("emailAddress")}
                                value={watch("emailAddress")}
                                placeholder="Your email"
                                error={errors?.emailAddress?.message}
                            />
                        </FieldContainer>

                        <FieldContainer
                            label="Phone Number"
                            id="phoneNumber"
                            layoutType="half"
                        >

                            <div className={cx("phoneColumn")}>
                                <Input
                                    placeholder="Area"
                                    {...register("areaCode")}
                                />
                                <Input
                                    placeholder="Phone Number"
                                    {...register("phoneNumber")}
                                />
                            </div>
                        </FieldContainer>

                    </div>
                    <div className={cx("row")}>
                        <FieldContainer
                            label="Your Department"
                            id="department"
                            required
                            layoutType="half"
                        >
                            {/* <div className={cx("column")}> */}
                            <Controller
                                name="department"
                                control={control}
                                render={({ field }: { field: ControllerRenderProps<FormValues, "department"> }) => {
                                    return (
                                        <Select
                                            {...field}
                                            error={errors?.department?.message ? "This field is required." : ""}
                                            placeholder="Choose Your Department"
                                            options={departmentOptions}
                                        />

                                    );

                                }}
                            />
                            {/* </div> */}
                        </FieldContainer>
                        <FieldContainer
                            label="Department to Report your Issue to"
                            id="problemArea"
                            required
                            layoutType="half"
                        >
                            {/* <div className={cx("column")}> */}
                            <Controller
                                name="problemArea"
                                control={control}
                                defaultValue={engineeringTicketAssignee ? null : "IT" }
                                render={({ field }: { field: ControllerRenderProps<FormValues, "problemArea"> }) => {
                                    return (
                                        <Select
                                            {...field}
                                            error={errors?.problemArea?.message ? "This field is required." : ""}
                                            placeholder="Choose Department"
                                            options={engineeringTicketAssignee ? reportDepartmentOptions : reportDepartmentOptionsITOnly }
                                        />

                                    );

                                }}
                            />
                            {/* </div> */}
                        </FieldContainer>
                    </div>
                    <FieldContainer
                        label="Please Describe Your Issue"
                        id="issue"
                        required
                        error={errors?.issue?.message}
                    >
                        <Textarea
                            {...register("issue")}
                            placeholder="Your issue..."
                            error={errors?.issue?.message}
                        />
                    </FieldContainer>
                    <div className={cx("row")}>
                        <FieldContainer
                            label="Room Numbers"
                            id="roomNumbers"
                            layoutType="half"
                        >

                            <Input placeholder="Share Room Numbers" {...register("roomNumbers")} />
                        </FieldContainer>
                        <FieldContainer
                            label="Location"
                            id="location"
                            layoutType="half"
                        >

                            <Input placeholder="Add Location" {...register("location")} />
                        </FieldContainer>

                    </div>
                    <div className={cx("row")}>
                        <FieldContainer
                            label=""
                            id="attachment1"
                            layoutType="half"
                            error={errors?.attachment1?.type}
                        >

                            <DropFileInput
                                fileList={watch("attachment1")}
                                title="Drag and drop file"
                                onDrop={(files: FileWithPath[]) => { setValue("attachment1", files); }}
                                onRemove={() => setValue("attachment1", null)}

                            />
                        </FieldContainer>
                        <FieldContainer
                            label=""
                            id="attachment2"
                            layoutType="half"
                        >

                            <DropFileInput
                                fileList={watch("attachment2")}
                                title="Drag and drop file"
                                onDrop={(files: FileWithPath[]) => { setValue("attachment2", files); }}
                                onRemove={() => setValue("attachment2", null)}

                            />
                        </FieldContainer>

                    </div>
                    <div className={cx("row")}>
                        <FieldContainer
                            label=""
                            id="attachment3"
                            layoutType="half"
                        >

                            <DropFileInput
                                fileList={watch("attachment3")}
                                title="Drag and drop file"
                                onDrop={(files: FileWithPath[]) => { setValue("attachment3", files); }}
                                onRemove={() => setValue("attachment3", null)}
                            />
                        </FieldContainer>
                        <FieldContainer
                            label=""
                            id="attachment4"
                            layoutType="half"
                        >

                            <DropFileInput
                                fileList={watch("attachment4")}
                                title="Drag and drop file"
                                onDrop={(files: FileWithPath[]) => { setValue("attachment4", files); }}
                                onRemove={() => setValue("attachment4", null)}

                            />
                        </FieldContainer>
                    </div>
                    <div className={cx("actions")}>
                        <Button
                            disabled={isSubmitting}
                            notActive={!!Object.keys(errors).length}
                            type="submit"
                            title="Submit Ticket"
                        />
                        <GhostButton
                            onClick={() => { reset(); }}
                            title="Discard Changes"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}


type Props = {
    form: UseFormReturn<FormValues, any, undefined>,
    engineeringTicketAssignee?: string,
    onSubmit: () => Promise<void>,
}

export default Form;

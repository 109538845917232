import React from "react";

import classnames from "classnames/bind";

import styles from "./FieldContainer.module.scss";

const cx: CX = classnames.bind(styles);

function FieldContainer({
    layoutType = "wide",
    label,
    error,
    id,
    required = false,
    children,
}: Props) {

    return (
        <div className={cx("wrapper", { error: !!error, half: layoutType === "half" })}>
            <label
                htmlFor={id}
            >
                {label}
                {
                    required &&
                    <span className={cx("required")}>*</span>
                }
            </label>
            <div className={cx("fields")}>
                {children}
            </div>
        </div>
    );
}

type Props = {
    layoutType?:string,
    label: string,
    id: string,
    required?:boolean,
    children: React.ReactNode[] | React.ReactNode,
    error?: string
}

export default FieldContainer;

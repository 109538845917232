import React from "react";

import classnames from "classnames/bind";

import styles from "./ErrorSubmission.module.scss";

const cx: CX = classnames.bind(styles);

function ErrorSubmission(props: Props) {
    return (
        <div className={cx("wrapper")}>
            <div>
                {!props.errorMessage && <h1>Some error ocurred</h1>}
                {props.errorMessage && <h2>{props.errorMessage}</h2>}
            </div>
        </div>
    );
}

type Props = {
    errorMessage?: string
}


export default ErrorSubmission;
const trimMiddleString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
        return str;
    }

    const halfMaxSize = Math.floor(maxLength / 2);

    return `${str.slice(0, halfMaxSize)}...${str.slice(-halfMaxSize)}`;
};

export {
    trimMiddleString,

};
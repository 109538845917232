import React, { ForwardedRef, forwardRef } from "react";

import classnames from "classnames/bind";

import styles from "./Textarea.module.scss";

const cx: CX = classnames.bind(styles);

const Textarea = forwardRef<HTMLTextAreaElement, Props>((props: Props, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { error, ...rest } = props;

    return (
        <div className={cx("wrapper", { error })}>
            <textarea
                ref={ref}
                {...rest}
            />
            {error && <div className={cx("error-field")}>{error}</div>}
        </div>
    );
});

Textarea.displayName = "Textarea";

type Props = React.HTMLProps<HTMLTextAreaElement> & {
    error?: string | boolean
};

export default Textarea;

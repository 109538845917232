import React from "react";

import classnames from "classnames/bind";

import styles from "./Logo.module.scss";

const cx: CX = classnames.bind(styles);

function Logo(props: Props) {
    const { src } = props;

    return (
        <div className={cx("wrapper")}>
            <img alt="Logo" className={cx("logo")} src={src} />
        </div>
    );
}


type Props = {
    src: string
}

export default Logo;

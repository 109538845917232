import React from "react";

import classnames from "classnames/bind";

import styles from "./Button.module.scss";

const cx: CX = classnames.bind(styles);

function Button(props: Props) {
    const { title, onClick, type, disabled, primary, notActive, ...rest } = props;

    return (
        <button
            type= {type === "submit" ? "submit" : "button"}
            onClick={onClick}
            className={cx("button", { primary: type === "submit" || primary, disabled, ["not-active"]: notActive })}
            disabled={disabled}
            {...rest}
        >
            {title}
        </button>
    );
}

type Props = React.HTMLProps<HTMLButtonElement> & {
    primary?:boolean;
    notActive?:boolean;
}

export default Button;

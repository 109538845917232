import React, { ForwardedRef, forwardRef } from "react";
import classnames from "classnames/bind";
import styles from "./Input.module.scss";

const cx: CX = classnames.bind(styles);


const Input: React.FC<Props> = forwardRef<HTMLInputElement, Props>((props: Props, ref: ForwardedRef<HTMLInputElement>) => {
  const { label, name, error, ...rest } = props;

  return (
      <div className={cx("wrapper", {error})}>
          <input
              ref={ref}
              id={name}
              name={name}
              {...rest}
      />
          {error && <div className={cx("error-field")}>{error}</div>}
      </div>
  );
});

Input.displayName = "Input";


type Props = {
    label?: string,
    name: string,
    placeholder?: string
    error?:string | boolean
} & React.HTMLProps<HTMLInputElement>

export default Input;

import Joi from "joi";
import {FormType} from "src/types";

export const attachmentValidation = (file: File) => {
    if ((file.size / 1000) > 10000)
        return {
            code: "file-too-large",
            message: "Name is larger than 10KB",
        };

    return null;
};

const submissionFormSchema = Joi.object({
    firstName: Joi.string()
        .trim()
        .required()
        .messages({
            "string.empty": "This field is required.",
        }),

    lastName: Joi.string()
        .trim()
        .required()
        .messages({
            "string.empty": "This field is required.",
        }),
    emailAddress: Joi.string()
        .trim()
        .email({tlds: {allow: false}})
        .required()
        .messages({
            "string.empty": "This field is required.",
            "string.email": "Field must be a valid email",
        }),

    phoneNumber: Joi.string()
        .trim()
        .allow(""),

    areaCode: Joi.string()
        .trim()
        .allow(""),

    department: Joi.string()
        .trim()
        .required()
        .messages({
            "string.empty": "This field is required.",
            "undefined": "This field is required",
        }),

    problemArea: Joi.string()
        .trim()
        .required()
        .messages({
            "string.empty": "This field is required.",
            "undefined": "This field is required",
        }),
    issue: Joi.string()
        .trim()
        .required()
        .messages({
            "string.empty": "This field is required.",
        }),

    roomNumbers: Joi.string()
        .trim()
        .allow(""),

    location: Joi.string()
        .trim()
        .allow(""),
}).unknown(true);

const formParamsSchema = Joi.object().keys({
    formType: Joi.string().valid(...Object.values(FormType)).trim().required(),
    relatedProject: Joi.string().trim().required(),
    siteTitle: Joi.string().trim().required(),
    engineeringTicketAssignee: Joi.string().trim().allow(""),
}).unknown(true);

const schemaByKey = {
    formParams: formParamsSchema,
};

const validate = (key: string, data: unknown) => {
    const schema: Joi.Schema = schemaByKey[key];

    if (!schema) throw new Error("No such key");

    return schema.validate(data, {allowUnknown: true});
};

export {submissionFormSchema, validate};
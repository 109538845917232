import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { submissionFormSchema, validate } from "./validation";
import { fetchFormParams, submitForm } from "./api";
import { FormParams, FormValues } from "./types";


function useApp() {

    const [screen, setScreen] = useState<"error" | "form" | "success">(null);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [allFormParams, setFormParams] = useState<FormParams>(null);

    const form = useForm<FormValues>({
        resolver: joiResolver(submissionFormSchema),
        mode: "onBlur",
    });

    const parseParams = (): Record<string, string> => {
        const result = {};
        const params = new URLSearchParams(window.location.search);

        for (const param of params) {
            result[param[0]] = param[1];
        }

        return result;
    };


    const validateParams = (params: Record<string, string>): FormParams | never => {
        const response: { value?: FormParams, error?: unknown} = validate("formParams", params);

        if(response.error) {
            throw new Error();
        }

        return response.value;
    };

    const getAdditionalParams = async (formParams: FormParams) => {
        try {
            const apiFormData = await fetchFormParams(formParams);

            if (apiFormData.status != "Open") {
                setErrorMessage(`The support queue for ${formParams.siteTitle} (${formParams.formType}) has now been closed, please reach out to you on site property IT Manager for support`);
                return setScreen("error");
            }

            setFormParams({
                ...formParams,
                logo: apiFormData.logo || "https://compass.apexdigital.online/secure/db/90539/res.aspx/MarriottITOP_logo.png",
            });

            setScreen("form");
        } catch(e) {
            setScreen("error");
        }
    };

    useEffect(() => {

        const params = parseParams();

        try {
            const formParams = validateParams(params);

            getAdditionalParams(formParams).catch((e: Error) => {throw e;});

        } catch (e) {
            setScreen("error");
        }
    }, []);

    const onSubmit = async () => {
        try {
            await submitForm(allFormParams, form.getValues());
            form.reset();
            setScreen("success");
        } catch (e) {
            setScreen("error");
        }
    };

    const onCreateNew = () => {
        setScreen("form");
    };


    return {
        form,
        screen,
        allFormParams,
        errorMessage,
        onSubmit,
        onCreateNew,
    };
}

export default useApp;
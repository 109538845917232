import { transformFormParams, transformSubmitForm } from "src/transformers";
import { FormParams, FormValues } from "src/types";

const baseURL = process.env.REACT_APP_API_URL;
const apiPrefix = process.env.REACT_APP_API_PREFIX;

type Status = "Opened" | "Closed";

async function request<T>({
    url, method, params, body,
}: { url: string, method: string, params?: URLSearchParams, body?: Record<string, unknown> | FormData }): Promise<T> | never {
    let fullUrl = `${baseURL}${apiPrefix}${url}`;

    if (params) {
        fullUrl += `?${params.toString()}`;
    }

    const transformedBody = body instanceof FormData ? body : JSON.stringify(body || {});
    const requestData = {
        url: fullUrl,
        options: {
            method,
            headers: {
                // "Content-Type": "application/json",
                "Cache-Control": "no-cache",
            },
            body: method !== "GET"
                ? transformedBody
                : undefined,
        },
    };

    const response = await fetch(
        requestData.url,
        requestData.options,
    );

    if (!response.headers.get("Content-Type")?.includes("application/json")) return null;

    const json = await response.json() as T;

    if ((response.status >= 200 && response.status < 300)) {
        return json;
    }

    throw json;
}

const submitForm = async (formParams: FormParams, formData: FormValues) => {
    const body = transformSubmitForm(formParams, formData);

    const response = await request<Array<{ status: number }>>({
        url: "/90539/21010A68F2704355A624B6D0CE776A2D/Ticket/create.json",
        method: "POST",
        body,
    });

    if (response?.[0]?.status < 200 || response?.[0]?.status > 299) throw new Error();
};

const fetchFormParams = async (formParams: FormParams): Promise<{ status: string, logo?: string, id: number }> | never => {
    const apiParams = transformFormParams(formParams);

    const searchParams = new URLSearchParams([
        ["column", "Logo URL"],
        ["column", apiParams.formStatusField],
        ["key", apiParams.relatedProject],
    ]);

    const data: Array<
        {
            ["Logo URL"]?: string,
            "@row.id": number,
            "Ticket Form Status (Post Opening)"?: Status,
            "Ticket Form Status (Countdown)"?: Status,
            "Ticket Form Status (Pre Opening Office)"?: Status,
        }
    > = await request({
        url: "/90539/21010A68F2704355A624B6D0CE776A2D/Project/retrieve.json",
        method: "GET",
        params: searchParams,
    });

    return {
        logo: data[0]["Logo URL"],
        status: data[0][apiParams.formStatusField],
        id: data[0]["@row.id"],
    };
};

export {
    submitForm,
    fetchFormParams,
};
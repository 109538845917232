import React, { MouseEventHandler } from "react";

import classnames from "classnames/bind";

import styles from "./GhostButton.module.scss";

const cx: CX = classnames.bind(styles);

function GhostButton(props: Props) {
    const { title, onClick } = props;

    return (
        <div
            tabIndex={0}
            role="button"
            onClick={onClick}
            className={cx("ghost-button")}
        >
            {title}
        </div>
    );
}

type Props = {
    title: string;
    onClick?: MouseEventHandler
};

export default GhostButton;

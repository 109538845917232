import React from "react";
import styles from "./App.module.scss";
import useApp from "./useApp";
import SuccessSubmission from "./components/screens/SuccessSubmission";

import ErrorSubmission from "./components/screens/ErrorSubmission";
import Header from "./components/Header";
import Form from "./components/Form";


function App() {
    const {
        form,
        screen,
        allFormParams,
        errorMessage,
        onCreateNew,
        onSubmit,
    } = useApp();

    return (
        <div className={styles["app"]}>
            {allFormParams?.logo &&
                <Header
                    logo={allFormParams.logo}
                    title={allFormParams.siteTitle}
                    formType={allFormParams.formType}
                />
            }
            {screen === "error" && <ErrorSubmission errorMessage={errorMessage} />}
            {screen === "form" &&
                <Form
                    form={form}
                    engineeringTicketAssignee={allFormParams.engineeringTicketAssignee}
                    onSubmit={onSubmit}
                />}
            {screen === "success" && <SuccessSubmission onClick={onCreateNew} />}
        </div>
    );
}

export default App;

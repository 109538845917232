import React from "react";

import classnames from "classnames/bind";

import { FileWithPath, useDropzone } from "react-dropzone";

import GhostButton from "src/components/GhostButton";

import styles from "./DropFileInput.module.scss";
import { trimMiddleString } from "src/utils";
import { attachmentValidation } from "src/validation";

const cx: CX = classnames.bind(styles);

function DropFileInput(props: Props) {
    const { title, onDrop, onRemove, fileList } = props;

    const { getRootProps, getInputProps, isDragAccept, isDragReject, fileRejections } = useDropzone({
        multiple: false,
        accept: {
            "application/pdf": [],
            "application/msword": [],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
            "application/vnd.ms-excel": [],
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
            "text/csv": [],
            "text/plain": [],
            "application/rtf": [],
            "text/html": [],
            "application/zip": [],
            "audio/mpeg": [],
            "audio/x-ms-wma": [],
            "video/mpeg": [],
            "video/x-flv": [],
            "video/x-msvideo": [],
            "image/jpeg": [],
            "image/png": [],
            "image/gif": [],
        },
        onDrop: (acceptedFiles: FileWithPath[]) => {
            onDrop(acceptedFiles);
        },
        validator: attachmentValidation,
    });

    return (
        <div className={cx("wrapper")}>
            <div className={cx("drop-file-input")}>
                <div
                    className={cx("base", {
                        "is-drag-accept": isDragAccept,
                        // TODO reject colors
                        "is-drag-reject": fileRejections?.length || isDragReject,
                    })}
                    {...getRootProps({ style: { cursor: "pointer" } })}
                >
                    {fileRejections?.length ?
                        <div className={cx("input-container")}>
                            <input {...getInputProps()} />
                            <div className={cx("error")}>Wrong File. Please try to upload new file</div>
                            <GhostButton title="Choose file" />
                        </div>
                        :

                        <div className={cx("input-container")}>
                            <input {...getInputProps()} />
                            <div className={cx("title")}>{title}</div>
                            <div className={cx("or")}>or</div>

                            <GhostButton title="Choose file" />
                        </div>
                    }
                </div>
            </div>
            {fileList &&
                <ul className={cx("upload-list")}>
                    {
                        Array.from(fileList)
                            .map((file: File) => (<li key={file.name}>
                                <div className={cx("file-data")}>
                                    <div className={cx("file-data-title")}>
                                        {file.type.includes("image") ?
                                            <img className={cx("image-file")} alt="Data Upload" src={URL.createObjectURL(file)} /> :
                                            <img alt="Data Upload" src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkZpbGVBcnJvd1VwIiBjbGlwLXBhdGg9InVybCgjY2xpcDBfMjU3XzI5OTApIj4KPHBhdGggaWQ9IlZlY3RvciIgb3BhY2l0eT0iMC4yIiBkPSJNMTkgNFYxMUgyNkwxOSA0WiIgZmlsbD0iIzRBN0E4QSIvPgo8cGF0aCBpZD0iVmVjdG9yXzIiIGQ9Ik0yNSAyOEg3QzYuNzM0NzggMjggNi40ODA0MyAyNy44OTQ2IDYuMjkyODkgMjcuNzA3MUM2LjEwNTM2IDI3LjUxOTYgNiAyNy4yNjUyIDYgMjdWNUM2IDQuNzM0NzggNi4xMDUzNiA0LjQ4MDQzIDYuMjkyODkgNC4yOTI4OUM2LjQ4MDQzIDQuMTA1MzYgNi43MzQ3OCA0IDcgNEgxOUwyNiAxMVYyN0MyNiAyNy4yNjUyIDI1Ljg5NDYgMjcuNTE5NiAyNS43MDcxIDI3LjcwNzFDMjUuNTE5NiAyNy44OTQ2IDI1LjI2NTIgMjggMjUgMjhaIiBzdHJva2U9IiM0QTdBOEEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBpZD0iVmVjdG9yXzMiIGQ9Ik0xOSA0VjExSDI2IiBzdHJva2U9IiM0QTdBOEEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBpZD0iVmVjdG9yXzQiIGQ9Ik0xOC41IDE1TDE1IDIwLjVMMTMgMTkiIHN0cm9rZT0iIzRBN0E4QSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjU3XzI5OTAiPgo8cmVjdCB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==' />
                                        }
                                        {trimMiddleString(file.name, 17)}

                                        <svg onClick={onRemove} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g id="X" clipPath="url(#clip0_257_2983)">
                                                <path id="Vector" d="M12.5 3.5L3.5 12.5" stroke="#4A7A8A" strokeLinecap="round" strokeLinejoin="round" />
                                                <path id="Vector_2" d="M12.5 12.5L3.5 3.5" stroke="#4A7A8A" strokeLinecap="round" strokeLinejoin="round" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_257_2983">
                                                    <rect width="16" height="16" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </li>))
                    }
                </ul>
            }
        </div>
    );
}

type Props = {
    title: string;
    fileList?: File[];
    onDrop: (acceptedFiles: Array<FileWithPath>) => void;
    onRemove: () => void
};

export default DropFileInput;

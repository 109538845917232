import React from "react";

import classnames from "classnames/bind";

import styles from "./SuccessSubmission.module.scss";
import Button from "src/components/Button/Button";
import Envelop from "src/assets/envelop.png";

const cx: CX = classnames.bind(styles);

function SuccessSubmission(props: Props) {
    return (
        <div className={cx("wrapper")}>
            <img alt="Success" src={Envelop} />
            <div>
                <h1>Thank You!</h1>
                <p>We will get back to you as soon as posible</p>
                <p>
                    To create another ticket please click the button below
                </p>
            </div>

            <Button type="submit" title="Create new" onClick={props.onClick} />
        </div>
    );
}

type Props = {
    onClick: () => void
}

export default SuccessSubmission;
import React, { ForwardedRef, forwardRef } from "react";
import ReactSelect, { CSSObjectWithLabel, ControlProps, DropdownIndicatorProps } from "react-select";
import classnames from "classnames/bind";

import styles from "./Select.module.scss";

const cx: CX = classnames.bind(styles);

/* eslint-disable quote-props */
const Select = forwardRef((props: Props, ref: ForwardedRef<HTMLSelectElement>) => {
    const { error, ...rest } = props;
    return (
        <div className={cx("wrapper")}>
            <ReactSelect
                {...rest}
                styles={{
                    control: (baseStyles: CSSObjectWithLabel, state: ControlProps<{ value: string; label: string }>) => ({
                        ...baseStyles,
                        borderColor: error ? "#DF715F" : state.isFocused ? "#ADB4BD" : "#D9DDE2",
                        outline: "none",
                        borderRadius: "8px",
                        fontSize: "0.875rem",
                        boxShadow: state.isFocused ? "none" : "none",
                        height: "40px",
                        zIndex: 11,
                        borderBottomLeftRadius: state.menuIsOpen ? 0 : "8px",
                        borderBottomRightRadius: state.menuIsOpen ? 0 : "8px",
                        paddingLeft: "calc(0.5rem - 1px)",

                        "&:hover": {
                            borderColor: error ? "#DF715F" : state.isFocused ? "#ADB4BD" : "#D9DDE2",
                            boxShadow: state.isFocused ? "none" : "none",
                        },
                    }),
                    placeholder: (base: CSSObjectWithLabel) => ({
                        ...base,
                        fontSize: "0.875rem",
                        color: "#ADB4BD",
                        fontWeight: 400,
                    }),
                    menuList: (base: CSSObjectWithLabel) => ({
                        ...base,
                        marginTop: 0,
                        "::-webkit-scrollbar": {
                            width: "0px",
                            height: "0px",
                        },
                        borderBottomLeftRadius: "8px",
                        borderBottomRightRadius: "8px",
                        border: "1px solid #ADB4BD",
                        boxShadow: "none",
                    }),
                    menu: (base: CSSObjectWithLabel) => {
                        return {
                            ...base,
                            background: "#fff",
                            marginTop: "-2px",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            zIndex: 10,
                            // width: "calc(100% - 2px)",
                            borderBottomLeftRadius: "8px",
                            borderBottomRightRadius: "8px",
                            borderColor: "#ADB4BD!important",
                            boxShadow: "none",
                            border: "none",
                            // marginLeft: "1px"
                        };
                    },
                    option: (base: CSSObjectWithLabel, { isDisabled, isSelected }: { isDisabled: boolean, isSelected: boolean }) => {
                        return {
                            ...base,
                            backgroundColor: "#fff",
                            padding: "12px",
                            fontSize: "0.875rem",
                            color: isSelected ? "#202020" : "#ADB4BD",
                            cursor: isDisabled ? "not-allowed" : "default",
                            "&:active": {
                                background: "#fff",
                            },
                            "&:hover": {
                                color: "#202020",
                            },
                        };
                    },
                }}
                isSearchable={false}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: (dropdownIndicatorProps: DropdownIndicatorProps) => (
                        <svg
                            style={{
                                transform: dropdownIndicatorProps.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
                                marginRight: "1rem",
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <g id="CaretDown" clipPath="url(#clip0_150_3041)">
                                <path id="Vector" d="M13 6L8 11L3 6" stroke="#4A7A8A" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_150_3041">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>),
                }}
                value={props.value ? { value: props.value, label: props.value } : undefined}
                ref={ref as any}
                onChange={(value: { value: string, label: string }) => props.onChange(value.value)}
            />
            {error && <div className={cx("error")}>{error}</div>}
        </div>
    );
});

Select.displayName = "Select";

type Props = {
    value: string
    onChange: (value: string) => void,
    onBlur: () => void,
    options: { value: string, label: string }[],
    placeholder?: string,
    error?: string
};

export default Select;

import { FormParams, FormType, FormValues } from "src/types";

// eslint-disable-next-line no-shadow
export enum FormTypeValues {
    "Ticket Form Status (Post Opening)" = "Ticket Form Status (Post Opening)",
    "Ticket Form Status (Countdown)" = "Ticket Form Status (Countdown)",
    "Ticket Form Status (Pre Opening Office)" = "Ticket Form Status (Pre Opening Office)"
}
const FormStatus = {
    [FormType["Post Opening"]]: FormTypeValues["Ticket Form Status (Post Opening)"],
    [FormType["Countdown"]]: FormTypeValues["Ticket Form Status (Countdown)"],
    [FormType["Pre Opening Office"]]: FormTypeValues["Ticket Form Status (Pre Opening Office)"],
};

export const transformFormParams = (formParams: FormParams): { formStatusField: FormTypeValues, relatedProject: FormParams["relatedProject"] } => {
    const { relatedProject, formType } = formParams;
    const formStatusField = FormStatus[FormType[formType]];
    return {
        relatedProject,
        formStatusField,
    };
};


export const transformSubmitForm = (formParams: FormParams, formData: FormValues) => {
    const fd = new FormData();

    const test = process.env.NODE_ENV === "development";

    fd.append("Requested By (Full Name)", `${formData.firstName} ${formData.lastName}`);
    fd.append("Requested By (Email Address)", formData.emailAddress);
    fd.append("Requested By (Phone)", `${formData.areaCode} ${formData.phoneNumber}`.trim());
    fd.append("Related Site", formParams.relatedSite);
    fd.append("Requested By (Department)", formData.department);
    fd.append("Opening Phase", formParams.formType);
    fd.append("Problem Area", formData.problemArea);
    fd.append("Project", formParams.relatedProject);
    fd.append("Room", formData.roomNumbers);
    fd.append("Location", formData.location);
    fd.append("Description", formData.issue);
    fd.append("Test", test.toString());

    Object.keys(formData)
        .filter((key: string) => key.includes("attachment"))
        .forEach((key: string) => {
            if((formData[key] as FileList)?.length) {
                const fileList: FileList = formData[key] as FileList;
                const fileIndex = key.slice(-1);
                fd.append(`File ${fileIndex}`, fileList[0]);
            }
        });

    return fd;
};